<template>
    <div class="section">
        <div class="item-title" @click="expandItem">
            <span>서류 발급 방법</span>
            <img
                :src="require(`@/assets/images/icons/${expanded ? 'arrow_open' : 'arrow_closed'}.png`)"
                width="24px"
                height="24px"
            />
        </div>
        <div v-if="expanded">
            <div v-for="(doc, index) in documents" :key="index">
                <div class="item-list">
                    <span class="badge">택 {{ index + 1 }}</span>
                    <span>{{ doc.title }}</span>
                </div>

                <div class="item-description" v-for="(method, idx) in doc.methods" :key="idx">
                    <div class="method-title" v-if="method.title">{{ method.title }}</div>
                    <div class="item-list" v-for="(desc, i) in method.description" :key="i">
                        <span v-if="method.description.length > 1">{{ desc.id + '. ' }} </span>
                        <div>{{ desc.content }}</div>
                    </div>
                </div>
                <hr class="hr" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccordionMethod',
    data: () => ({
        expanded: false,
    }),
    props: {
        documents: {
            type: Array,
            required: false,
        },
    },
    computed: {},
    methods: {
        expandItem() {
            this.expanded = !this.expanded
        },
    },
}
</script>

<style lang="scss" scoped></style>
