<template>
    <div class="page-modal" v-if="badge.id != 15">
        <div class="back-button-wrapper" @click="onClickClose">
            <!-- <i class="back-button material-icons">close</i> -->
            <img class="back-button" :src="require(`@/assets/images/icons/close.png`)" width="24px" height="24px" />
            <span class="header-title">인증센터</span>
        </div>
        <div class="main-title">{{ badge.title }}</div>
        <section class="section">
            <div class="item-title">필요 서류</div>
            <div class="item-description" v-if="badge.description" v-html="badge.description" />
            <div v-for="(doc, index) in verificationMethod" :key="index">
                <div class="item-list">
                    <span class="badge">택 {{ index + 1 }}</span>
                    <span class="doc-name">{{ doc }}</span>
                </div>
            </div>
        </section>
        <AccordionMethod v-if="verificationMethodDetail.length" :documents="verificationMethodDetail" />
        <AddPhoto :badge="badge" @input="onInputPhoto" />

        <BottomButton :label="`제출하기`" @click="onClickSubmit" :disabled="disabled" />
    </div>
    <div class="page-modal" v-else>
        <div class="back-button-wrapper" @click="onClickClose">
            <!-- <i class="back-button material-icons">close</i> -->
            <img class="back-button" :src="require(`@/assets/images/icons/close.png`)" width="24px" height="24px" />
            <span class="header-title">직장증명</span>
        </div>
        <ul class="certificate-tabs">
            <li class="tab" v-for="tab in tabs" :key="tab" @click="onClickTab(tab)">
                <span class="label" v-html="$translate(`TAB_${tab}`)" :class="{ active: isSelected(tab) }" />
                <div v-show="isSelected(tab)" class="indicator" />
            </li>
        </ul>
        <div v-if="selected === 'BUSINESS_CARD'">
            <div class="main-title">{{ badge.title }}</div>
            <section class="section">
                <div class="item-title">필요 서류</div>
                <!-- <div class="item-description" v-if="badge.description" v-html="badge.description" /> -->
                <div class="item-list">
                    <span class="badge">필수</span>
                    <span class="doc-name">이름, 기업명이 나온 명함</span>
                </div>
            </section>
            <section class="section">
                <div class="item-title">유의사항</div>
                <!-- <div class="item-description" v-if="badge.description" v-html="badge.description" /> -->
                <div class="item-list">
                    <!-- <span class="badge">필수</span> -->
                    <span class="doc-name">1. 명함에 적힌 성명과 계정의 성명이 일치해야 합니다.</span>
                </div>
                <div class="item-list">
                    <span class="doc-name">2. 명함 인증이 어려울 경우 서류 인증을 진행해주세요.</span>
                </div>
                <div class="item-list">
                    <span class="doc-name">3. 기업명이 뒷면에 있는 경우 앞면과 뒷면을 각각 찍어 모두 올려주세요.</span>
                </div>
            </section>
            <!-- <AccordionMethod v-if="verificationMethodDetail.length" :documents="verificationMethodDetail" /> -->
            <AddPhoto :badge="badge" :certificateTab="selectedTab" @input="onInputPhoto" />
            <BottomButton :label="`제출하기`" @click="onClickSubmit" :disabled="disabled" />
        </div>
        <div v-else>
            <div class="main-title">{{ badge.title }}</div>
            <section class="section">
                <div class="item-title">필요 서류</div>
                <div class="item-description" v-if="badge.description" v-html="badge.description" />
                <div v-for="(doc, index) in verificationMethod" :key="index">
                    <div class="item-list">
                        <span class="badge">택 {{ index + 1 }}</span>
                        <span class="doc-name">{{ doc }}</span>
                    </div>
                </div>
            </section>
            <AccordionMethod v-if="verificationMethodDetail.length" :documents="verificationMethodDetail" />
            <AddPhoto :badge="badge" @input="onInputPhoto" />

            <BottomButton :label="`제출하기`" @click="onClickSubmit" :disabled="disabled" />
        </div>
    </div>
</template>

<script>
import verificationBadgeService from '@/services/verification-badge'
import { verifyMethod } from '@/assets/constants/cert-center'
import AccordionMethod from '../../routes/my-page/components/AccordionMethod'
import AddPhoto from '../../routes/my-page/components/AddPhoto'
export default {
    name: 'ModalCertificate',
    props: {
        options: {
            type: Object,
        },
    },
    components: {
        AccordionMethod,
        AddPhoto,
    },
    data: () => ({
        profileItems: [],
        showSearch: true,
        searchValue: '',
        numberValue: 0,
        photos: [],
        selectedTab: 'BUSINESS_CARD',
        tabs: ['BUSINESS_CARD', 'DOCUMENT'],
    }),
    computed: {
        selected() {
            return this.selectedTab
        },
        disabled() {
            return this.photos.length === 0
        },
        badge() {
            return this.options.verificationBadge
        },
        verificationMethod() {
            return this.badge.verification_method.split('|')
        },
        verificationMethodDetail() {
            return verifyMethod[this.badge.id]
        },
        isReSubmit() {
            const declined = this.$store.getters.myVerificationBadges.filter(
                badge => !badge.enabled && badge.rejection_reason
            )

            return declined.some(badge => badge.verification_badge_id === this.badge.id)
        },
    },

    methods: {
        isSelected(tab) {
            return this.selected === tab
        },
        onClickTab(tab) {
            this.selectedTab = tab
        },
        async onClickSubmit() {
            const payload = this.preparedPayload()
            this.$loading(true)
            try {
                await verificationBadgeService.create(this.$store.getters.me.id, payload)
                await this.$store.dispatch('loadMyVerificationBadges')

                // 반려사유 얼럿 플래그 지울지말지
                localStorage.removeItem('check-decline-reason')
                this.$toast.success('서류가 제출되었어요')
            } catch (e) {
                this.$toast.error(e.data.msg)
            } finally {
                this.$loading(false)
                this.$emit('close')
            }
        },
        onClickClose() {
            this.$emit('close')
        },
        preparedPayload() {
            try {
                const payload = new FormData()

                payload.append('user_id', this.$store.getters.me.id)
                payload.append('verification_type', 'photo')
                payload.append('verification_badge_id', this.badge.id)

                this.photos.forEach((photo, idx) => {
                    if (photo.blob) {
                        payload.append(`photo${idx + 1}`, photo.blob, photo.name)
                    }
                })

                return payload
            } catch (e) {
                console.log(e)
            }
        },
        onInputPhoto(e) {
            this.photos = e
        },
    },
}
</script>
<style scoped lang="scss">
.page-modal {
    padding: 0;
    @include modal-fullscreen;
    .back-button-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        // position:absolute;
        // top:0;
        position: sticky;
        top: 0;
        z-index: 3;
        background-color: white;
        .header-title {
            font-size: 18px;
            line-height: 28px;
        }
        .back-button {
            width: 24px;
            height: 24px;
            display: inline-block;
            margin: 14px 8px 14px 16px;
        }
    }
    .main-title {
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: #111111;
    }
    ::v-deep .section {
        padding: 0 16px;
        margin-bottom: 48px;
        .item-title {
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
            color: #111111;
            margin: 20px 0;
            display: flex;
            justify-content: space-between;
        }
        .item-description {
            .method-title {
                font-weight: bold;
                font-size: 14px;
                line-height: 24px;
                color: #393d44;
                margin: 2px 0px;
            }
        }
        .item-list {
            display: flex;
            align-items: center;
            margin: 11px 0;
            & div {
                font-size: 14px;
                line-height: 24px;
            }
            .badge {
                font-weight: 500;
                font-size: 10px;
                line-height: 18px;
                text-align: center;
                color: #ffffff;
                margin-right: 10px;
                background: #3da1ff;
                border-radius: 100px;
            }
            .doc-name {
                color: #111;
            }
        }
        .hr {
            margin: 40px 0;
        }
    }

    .certificate-tabs {
        display: flex;
        border-bottom: solid 1px $grey-02;

        position: sticky;
        top: 0;
        background-color: white;

        .tab {
            position: relative;
            width: calc(100% / 2);
            height: 48px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .label {
            font-size: 16px;
            color: $grey-06;

            &.active {
                color: $grey-09;

                @include f-medium;
            }
        }

        .indicator {
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: $grey-09;
            bottom: 0;
        }
    }
}
</style>
